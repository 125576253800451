import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Login.css'
import { PosLoginWithToken, PostImageDataWithToken } from '../../api/apiHelper'
import axios from 'axios'
const Login = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  const [forgetPassword, setForgetPassword] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [passId, setPassId] = useState('')

  const PosLogin = async data => {
    let obj = {
      username: data?.username,
      password: data?.password,
      source: 'web'
    }
    let headers = {
      'Content-Type': 'multipart/form-data'
    }

    const res = await axios.post(
      'https://uatbpm.retroinsurance.in/api/v1/employee/User',
      obj,
      { headers: headers }
    )
    console.log('Login Res', res)
    if (res?.data?.status) {
      window.location.href = res?.data?.data?.redirection_url
    } else {
      alert('Invalid Username or Password')
    }
  }

  const handleBecomePos = () => {
    window.location.href = 'https://uat.retroinsurance.in/becomepos'
  }

  const handleForgetPassword = async val => {
    setLoading(true)
    let headers = {
      'Content-Type': 'multipart/form-data'
    }
    let obj = {
      Email: val?.Email,
      source: 'web',
      user_type: 2
    }
    const response = await axios.post(
      'https://uatbpm.retroinsurance.in/api/v1/employee/Employee/send_one_time_password',
      obj,
      { headers: headers }
    )

    console.log(response, 'OTP response')
    if (response?.data?.status) {
      setForgetPassword(false)
      setResetPassword(true)
      setPassId(response?.data?.id)
      toast.success(response?.data?.message)
    }else{
      toast.error(resetRes?.data?.message)
    }
    setLoading(false)
  }

  const handleResetPassword = async (val) => {
    setLoading(true)
    let headers = {
      'Content-Type': 'multipart/form-data'
    }
    let obj = {
      id: passId,
      otp: val?.otp,
      pass: val?.pass,
      confirm_pass: val?.confirm_pass,
      user_type: 2
    }
    const resetRes = await axios.post(
      'https://uatbpm.retroinsurance.in/api/v1/employee/Employee/update_password',
      obj,
      { headers: headers }
    )

    console.log(resetRes, 'OTP response')
    if (resetRes?.data?.status) {
      setForgetPassword(false)
      setResetPassword(false)
      toast.success(resetRes?.data?.message)
    }else{
      toast.error(resetRes?.data?.message)
    }
    setLoading(false)
  }

  const handleBackToLogin = () => {
    setForgetPassword(false)
    setResetPassword(false)
  }
  return (
    <>
      <ToastContainer />

      <div className='login_wrapper'>
        <header className='header_login'>
          <div className='header-container-lg-block header-container-md-none container'>
            <div className='header__logo'>
              <div className='logo-src'>
                <img src='/assets/img/final-logo.png'></img>
              </div>
              <div className='sign-up_btn'>
                <div onClick={() => handleBecomePos()} className='sign_link'>
                  Become A POS
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className='main_wrapper'>
          <div className='container-fluid'>
            <div className='row align-items-center gy-sm-5 gy-5'>
              <div className='col-12 col-lg-8'>
                <div className='about_insurance'>
                  <div className='user'>
                    <figure>
                      <img
                        src='/assets/img/personimage.png'
                        alt='user-img'
                        className='user_img'
                      />
                    </figure>
                  </div>
                  <div className='user_det'>
                    <span className='user_text'>Become A</span>
                    <h1 className='main_heading'>
                      R E T R O <b>INSURANCE</b>
                    </h1>
                    <span className='user_text'>
                      R E T R O INSURANCE POSP/ADVISOR &
                    </span>
                    <div className='earn_amount'>
                      <span className='earn'>Earn Upto</span>
                      <span className='amount'>₹50,000/ Month*</span>
                    </div>
                    <p className='invest'>Zero Investment Required</p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-4'>
                <div className='login_det'>
                  {forgetPassword === false && resetPassword === false ? (
                    <h2 className='form_heading'>Login As POSP</h2>
                  ) : forgetPassword === true ? (
                    <h2 className='form_heading'>Forget Password</h2>
                  ) : resetPassword === true ? (
                    <h2 className='form_heading'>Reset Password</h2>
                  ) : (
                    ''
                  )}
                  {/* <h2 className='form_heading'>Login As POSP</h2> */}

                  {forgetPassword === false && resetPassword === false && (
                    <form
                      className='login_form'
                      onSubmit={handleSubmit(PosLogin)}
                    >
                      <label className='custom-field two'>
                        <input
                          type='text'
                          placeholder=''
                          name='username'
                          id='username'
                          {...register('username', {
                            required: 'Username is required'
                          })}
                        />
                        <span className='placeholder'>Enter Username</span>
                        <p className='mb-0 f-error'>
                          {errors?.username?.message}
                        </p>
                      </label>

                      <label className='custom-field two'>
                        <input
                          type='password'
                          placeholder=''
                          name='pass'
                          {...register('password', {
                            required: 'Password is required',
                            minLength: {
                              value: 6,
                              message: 'Password must be at least 6 characters'
                            }
                          })}
                        />
                        <span className='placeholder'>Enter Password</span>
                        <p className='mb-0 f-error'>
                          {errors?.password?.message}
                        </p>
                      </label>

                      <div className='login_btn'>
                        <button
                          className='submitButtonLogin'
                          type='submit'
                          id='btn-size'
                        >
                          {loading == true ? (
                            <span className='spinner-border spinner-border-sm'></span>
                          ) : (
                            'Login'
                          )}
                        </button>
                      </div>
                      <span
                        className='contact_link'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setForgetPassword(true)}
                      >
                        Forget Password?
                      </span>
                    </form>
                  )}

                  {forgetPassword && (
                    <form onSubmit={handleSubmit(handleForgetPassword)}>
                      <label className='custom-field two'>
                        <input
                          type='text'
                          placeholder=''
                          name='Email'
                          id='Email'
                          {...register('Email', {
                            required: 'Email is required'
                          })}
                        />
                        <span className='placeholder'>Enter Email</span>
                        <p className='mb-0 f-error'>{errors?.Email?.message}</p>
                      </label>
                      <div className='login_btn'>
                        <button
                          className='submitButtonLogin'
                          type='submit'
                          id='btn-size'
                        >
                          {loading == true ? (
                            <span className='spinner-border spinner-border-sm'></span>
                          ) : (
                            'Send OTP'
                          )}
                        </button>
                      </div>
                      <span
                        className='contact_link'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleBackToLogin()}
                      >
                        Back to Login
                      </span>
                    </form>
                  )}

                  {resetPassword && (
                    <form onSubmit={handleSubmit(handleResetPassword)}>
                      <label className='custom-field two'>
                        <input
                          type='number'
                          placeholder=''
                          name='otp'
                          id='otp'
                          {...register('otp', {
                            required: 'OTP is required'
                          })}
                        />
                        <span className='placeholder'>Enter OTP</span>
                        <p className='mb-0 f-error'>{errors?.otp?.message}</p>
                      </label>

                      <label className='custom-field two'>
                        <input
                          type='password'
                          placeholder=''
                          name='pass'
                          id='pass'
                          {...register('pass', {
                            required: 'Password is required'
                          })}
                        />
                        <span className='placeholder'>Enter Password</span>
                        <p className='mb-0 f-error'>{errors?.pass?.message}</p>
                      </label>

                      <label className='custom-field two'>
                        <input
                          type='password'
                          placeholder=''
                          name='confirm_pass'
                          id='confirm_pass'
                          {...register('confirm_pass', {
                            required: 'Password is required'
                          })}
                        />
                        <span className='placeholder'>Confirm Password</span>
                        <p className='mb-0 f-error'>{errors?.otp?.message}</p>
                      </label>
                      <div className='login_btn'>
                        <button
                          className='submitButtonLogin'
                          type='submit'
                          id='btn-size'
                        >
                          {loading == true ? (
                            <span className='spinner-border spinner-border-sm'></span>
                          ) : (
                            'Submit'
                          )}
                        </button>
                      </div>
                    </form>
                  )}

                  <span className='terms'>
                    By continuing, you agree with our <b>Terms & Conditions.</b>
                  </span>
                  <p className='support'>
                    Need help? Contact our team support at
                  </p>
                  <a href='#!' className='contact_link'>
                    help@retroinsurance.in{' '}
                    <span style={{ fontSize: '16px', fontWeight: '700' }}>
                      or
                    </span>{' '}
                    91(97173 71800)
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="left_wrapper"></div>
              <div className="login_heading">
                <h1 className="main_text">
                  Become A Turtlemint Insurance PoSP/Advisor &
                </h1>
                <h2 className="amount">Earn up to $50,000/Month*</h2>
                <span className="invest">Zero Investment Required</span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="right_wrapper">
                <h2 className="amount">Verify your mobile number</h2>
                <form>
                  <label className="custom-field two">
                    <input
                      type="email"
                      placeholder=""
                      name="uname"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    <span className="placeholder">Enter Username</span>
                    <p className="mb-0 f-error">{errors?.email?.message}</p>
                  </label>

                  <label className="custom-field two">
                    <input
                      type="password"
                      placeholder=""
                      name="pass"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message: "Password must be at least 6 characters",
                        },
                      })}
                    />
                    <span className="placeholder">Enter Password</span>
                    <p className="mb-0 f-error">{errors?.password?.message}</p>
                  </label>

                  <div className="login_btn">
                    <button
                      className="submitButtonLogin"
                      type="submit"
                      id="btn-size"
                    >
                      {loading == true ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        "Sign In"
                      )}
                    </button>
                  </div>
                </form>

                <span className="terms">
                  By continuing, you agree with our <b>Terms & Conditions.</b>
                </span>
                <p className="support">
                  Need help? Contact our team support at
                </p>
                <a href="#!" className="contact_link">
                  pospsupport@turltlemint.com{" "}
                  <span style={{ fontSize: "16px", fontWeight: "500" }}>
                    or
                  </span>{" "}
                  18002660101
                </a>
              </div>
            </div>
          </div> */}
          </div>
        </div>
        <div className='benefits_section'>
          <div className='container'>
            <h2 className='sec_heading'>
              Benefits of Becoming{' '}
              <b style={{ fontWeight: '900', color: '#35508a' }}>R E T R O</b>{' '}
              <b style={{ fontWeight: '900', color: '#30959c' }}>Insurance</b>{' '}
              <span
                style={{ fontWeight: '500', color: '#000', fontSize: '28px' }}
              >
                {' '}
                POSP (Point of Sales Person)
              </span>
            </h2>
            <p className='sec_text'>
              Understand your customers, Search for the relevant quote, Sell
              policy, and earn income. Know about the benefits of <br></br>{' '}
              Becoming a POSP Insurance advisor.
            </p>
            <div className='benefit_list'>
              <div className='row gy-sm-4'>
                <div className='col-lg-3 col-sm-6'>
                  <div className='posp_plan'>
                    <div className='plan_img'>
                      <img
                        src='/assets/img/timply pay out.png'
                        alt='payout-img'
                      />
                    </div>
                    <h3 className='plan_heading'>Timely Payout</h3>
                    <p className='plan_text'>
                      Receive Payments on Time Without any delays
                    </p>
                  </div>
                </div>
                <div className='col-lg-3 col-sm-6'>
                  <div className='posp_plan'>
                    <div className='plan_img'>
                      <img src='/assets/img/no paper.png' alt='payout-img' />
                    </div>
                    <h3 className='plan_heading'>No Paperwork</h3>
                    <p className='plan_text'>100% Digital Process</p>
                  </div>
                </div>
                <div className='col-lg-3 col-sm-6'>
                  <div className='posp_plan'>
                    <div className='plan_img'>
                      <img src='/assets/img/training.png' alt='payout-img' />
                    </div>
                    <h3 className='plan_heading'>Professional Training</h3>
                    <p className='plan_text'>Get Trained by Industry Expert</p>
                  </div>
                </div>
                <div className='col-lg-3 col-sm-6'>
                  <div className='posp_plan'>
                    <div className='plan_img'>
                      <img src='/assets/img/support.png' alt='payout-img' />
                    </div>
                    <h3 className='plan_heading'>Dedicated Support</h3>
                    <p className='plan_text'>
                      Get assistance on all the 7 days for policy issuance and
                      claim assistance
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='whatsapp'>
          <img src='/assets/img/whatsapp.jpeg'></img>
        </div>
        <div className='help_sec'>
          <div className='help_box'>
            <div className='help_text'>
              <span>Need Help? call us at</span>
              <a href='#!'>+91(97173 71800)</a>
            </div>
            <div className='help_img'>
              <img src='/assets/img/help.jpeg'></img>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="maindiv">
        <div className="image-info">
          <img
            src="/assets/images/homeinsurance.png"
            alt="Avatar"
            className="avatar"
          />
        </div>

        <form onSubmit={handleSubmit(handleLogin)} className="form-pos-login">
          <h2>
            Welcome To R E T R O {loginAs == "pos" ? "PosP" : "Employee"} Login
          </h2>
          <div className="container">
            <label
              for="uname"
              className="label-className-name"
              style={{ width: "5rem" }}
            >
              <b>Email</b>
            </label>

            <input
              type="email"
              placeholder="Enter Username"
              name="uname"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            <p className="mb-0 f-error">{errors?.email?.message}</p>

            <label for="pass" className="label-className-name">
              <b>Password</b>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="pass"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
            />
            <p className="mb-0 f-error">{errors?.password?.message}</p>
          </div>

          <div className="container-1">
            <button className="submitButtonLogin" type="submit" id="btn-size">
              {loading == true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Sign In"
              )}
            </button>
          </div>
          <br />
          <br />
          <label className="remember">
            <span className="forgot-password-className">
              <Link to="/forgetpassword">Forgot Password?</Link>
            </span>
          </label>
        </form>
      </div> */}
    </>
  )
}

export default Login
